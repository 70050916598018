import { ApiCode } from '@/manager/const';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import qs from 'qs';
import { tokenController } from '../manager/token';
import { FRESH_TOKEN_URL } from './protocol';
import { showToast } from 'vant';
import { lang } from '@/lang';

const service: AxiosInstance = axios.create({
  baseURL: window.server || process.env.VUE_APP_SERVER,
  timeout: 10 * 1000, // 请求超时时间
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
});
service.interceptors.request.use(config => {
  config.headers.auth = tokenController.token;
  config.headers.lang = lang.m
  return config;
});

service.interceptors.response.use(
  (response: AxiosResponse) => {
    const data = response.data;
    // 获取当前请求的接口地址
    if (data.code === 0) {
      return data.data;
    }
    if (data.code === ApiCode.UN_AUTH) {
      //退出登录
      return freshToken().then(() => service(response.config));
    } else if (
      data.code === ApiCode.FRESH_TOKEN_EXPIRE ||
      data.code === ApiCode.FRESH_TOKEN_UNKNOWN
    ) {
      // 长token已失效
      return Promise.reject(new Error('session timeout'));
    } else if (data.code === ApiCode.TOKEN_EXPIRE_UPDATA) {
      //如果长token失效刷新token
      tokenController.updateFreshToken(data.data);
      window.location.reload();
      return Promise.reject(new Error('should restart'));
    } else {
      if (data.code > 10000) {
        if (data.code == 20016) {
          // forceReload();
        } else {
          // 普通错误，警告提醒
          // tip.error(data.msg); todo
        }
      } else {
        // 业务严重错误，弹窗提醒
        // tip.error(data.msg); todo
      }
      showToast(data.msg || 'server error');
      return Promise.reject(new Error(data.msg || 'server error'));
    }
  },
  err => {
    if (err.config.headers.auto) {
      showToast(err.message || 'network error , please try again later');
    }
    return Promise.reject(err.response);
  }
);

let freshPromise: Promise<void> | undefined = undefined;

export function freshToken() {
  if (!navigator.onLine) {
    return Promise.reject(new Error('network error'));
  }
  const freshToken = tokenController.freshToken;
  if (freshToken) {
    freshPromise = post<string, { freshToken: string }>(FRESH_TOKEN_URL, {
      freshToken,
    })
      .then(resp => tokenController.updateToken(resp))
      .catch((err: Error) => {
        if (err.message == 'session timeout') {
          // openLogin();
          return Promise.reject(new Error('session timeout'));
        }
      })
      .finally(() => {
        freshPromise = undefined;
      });
    return freshPromise;
  } else {
    // 跳转到登录 todo
    // openLogin();
    return Promise.reject(new Error('session timeout'));
  }
}

export async function get<R = any, T = any>(
  url: string,
  params?: T,
  header?: Record<string, any>
): Promise<R> {
  const query = params ? qs.stringify(params) : undefined;
  const connectSymbol = url.includes('?') ? '&' : '?';
  return await service.get(
    query ? `${url}${connectSymbol}${query}` : url,
    header
  );
}

export async function post<R = any, T = any>(
  url: string,
  body?: T,
  header?: Record<string, any>
): Promise<R> {
  return await service.post(url, body, header);
}

export default service;
