import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Register from '../views/Register.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/article',
    name: 'Article',
    component: () =>
      import(/* webpackChunkName: "article" */ '../views/Article.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
