export const FRESH_TOKEN_URL = 'v1/global/fresh-token';

export const AUTO_LOGIN = '/v1/user/autoLogin';

export interface AutoLoginResp {
  id: number;
  name: string;
  avatar: string;
  token: string;
  email: string;
  level: number;
  cashBack: number;
  createTime: string;
  isFirstRecharge: number;
  pixelId?: string;
  exp: number;
  oldUserExpireTime?: string;
}

export const GET_CODE = '/server/auth/send/captcha';
export interface GetCodeReq {
  email: string;
}

export interface UserData {
  userId: number;
  nickname: string;
  avatar: string;
  gender: string;
  vipExpiredTime: string;
  vipExpiredTimeStamp: number;
  vipTitle: string;
  points: number;
  bindSocial: boolean;
  socialType: string;
  email: string;
  mobile: string;
  imUserId: number;
  autoUnlockEpisode: boolean;
  payPassword: boolean;
  payPasswordPrompt: boolean;
}

export const REGISTER_BY_EMAIL = '/server/auth/login/account';
export interface RegisterByEmailReq {
  clientType: string;
  invitationCode?: string;
  email: string;
  mobile?: string;
  captcha: string;
}
export interface RegisterByEmailResp {
  userInfo: UserData;
  tokenInfo: {
    access_token: string;
    refresh_token: string;
  };
}

export interface InvestmentItem {
  id: number;
  name: string;
  /**当前收益率 */
  income: string;
  /**昨日收益率 */
  yesterdayIncome: string;
  userMinAmount: string;
  userMaxAmount: string;
  /**项目封面 */
  content: string;
  /**版权进度 */
  progress: string;
  /**进度金额（当前筹得） */
  progressMoney: string;
  /**虚拟浏览量 */
  virtualViews: number;
  totalMoney: string;
  /**	结束时间 */
  endTime: string;
  intervalMoneys: string[];
}
export const INVESTMENT_LIST = '/server/market/investment/page';
export interface InvestmentListReq {
  /**状态 1正在进行 2已结束 */
  status: number;
  page: number;
  limit: number;
  /**搜索内容 */
  search?: string;
  /**搜索类型 */
  searchType?: string;
  /**	排序字段 */
  order?: string;
  /**是否升序 */
  asc?: string;
}
export interface InvestmentListResp {
  total: number;
  list: InvestmentItem[];
  extend: any;
}
