import { tokenController } from '@/manager/token';
import { get, post } from '.';
import {
  GET_CODE,
  GetCodeReq,
  RegisterByEmailResp,
  RegisterByEmailReq,
  REGISTER_BY_EMAIL,
  InvestmentListReq,
  InvestmentListResp,
  INVESTMENT_LIST,
} from './protocol';
import dataCenter from '@/dataCenter';

export async function fetchCode(email: string) {
  try {
    await post<null, GetCodeReq>(GET_CODE, { email });
    return 'success';
  } catch (error) {
    // nothing
  }
}

export async function fetchRegister(req: RegisterByEmailReq) {
  try {
    const resp = await post<RegisterByEmailResp, RegisterByEmailReq>(
      REGISTER_BY_EMAIL,
      req
    );
    tokenController.update(
      resp.tokenInfo.access_token,
      resp.tokenInfo.refresh_token
    );
    dataCenter.user.data = resp.userInfo;
    return resp.userInfo;
  } catch (error) {
    // nothing
  }
}

export async function fetchInvestmentList(req: InvestmentListReq) {
  try {
    return await get<InvestmentListResp, InvestmentListReq>(
      INVESTMENT_LIST,
      req
    );
  } catch (error) {
    // nothing
  }
}
