import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/reset.css';
import 'vant/lib/index.css';
import 'element-plus/dist/index.css';
import { initRouter } from './manager/viewManager';

initRouter(router);
createApp(App).use(router).mount('#app');
