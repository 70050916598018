/* 英语 */
export default {
  emailRegister: 'Email Register',
  registerNotice:
    'Unregistered accounts are automatically registered and logged in',
  enterEmail: 'Please enter your email address',
  enterCode: 'Please enter your code',
  getCode: 'Get code',
  signIn: 'Sign in now',
  signPropt: 'By logging in, you agree to the',
  policy: 'Privacy Policy',
  agreement: 'User Agreement',
  emailRuleError: 'The email format is incorrect',

  starcard: `Star Card member benefits`,
  more: `More`,
  describe:
    `Star card is the proof of rights for the Jubao Basin promotion system. Only with a Star card can one enjoy a series of benefits and benefits from the Jubao Basin promotion system. Star cards are divided into experience star cards and exclusive star cards, and star card users can receive cash rewards.`,
  starReward: `Star Card Promotion Rewards`,
  unlock: ` unlock`,
  text1:
    `· Invitation Reward: Directly or indirectly inviting users to purchase Star Card membership can earn 10% and 5% rewards.`,
  text2:
    `· Investment copyright promotion reward: Directly or indirectly invite users to invest in copyright promotion and receive 10% and 5% of daily income rewards.`,
  text3:
    `· Movie watching rewards: You can enjoy a 40% reward for directly inviting users to unlock a single episode or a 20% reward for subscribing to a short drama membership.`,
  starClu: `Star Cluster Rewards`,
  text4:
    `For specific reward rules, please consult the official customer service or inviter`,
  copyright: `Copyright crowdfunding`,
  td1: `annualized return`,
  td2: `Initial investment amount`,
  td3: `Completed/Total amount`,
  warning:
    `*According to the actual effect of promotion, the revenue will fluctuate daily.`,
  schedule: `Crowdfunding progress`,
  buynow: `Buy now`,
  download: `Download`,
  go: `Go`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Characteristic`,
  Participation: `Participation amount`,
  method: `Payment method`,
  amount: `Payment amount`,
  jump: `Jump payment`,
  name1: `skits`,
  name2: `Multiple types`,
  name3: `Star card member`,
  name4: `Pipeline income`,
  describe1: `Quality assurance`,
  describe2: `Think what you think`,
  describe3: `Ten-billion subsidy`,
  describe4: `Sideline top`,
  warning1: `The input content cannot be empty`,
  success1: `Binding successful`,
  ends: `Countdown ends`,
  warning2: `You have not logged in yet, please log in first`,
};
