import { Router } from 'vue-router';

let _router: Router;
export function initRouter(router: Router) {
  _router = router;
}
/**
 * 可以在非vue里面拿到路由来进行跳转，主要是避免循环依赖
 * @returns Router
 */
export function getRouter() {
  return _router;
}
